import React, { useState, useEffect } from 'react';
import '../App.css'; // Assuming styles are reusable

function Search() {
  const [userInput, setUserInput] = useState('');
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [dots, setDots] = useState(0);

  useEffect(() => {
    let interval;
    if (loading) {
      interval = setInterval(() => {
        setDots(prevDots => (prevDots + 1) % 4);
      }, 1000);
    } else {
      setDots(0);
    }
    return () => clearInterval(interval);
  }, [loading]);

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setResponse('');
    fetch('/api/query', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ query: userInput })
    })
      .then(response => response.json())
      .then(data => {
        setLoading(false);
        if (data.message) {
          setResponse(data.message);
        } else {
          setResponse("Error: " + (data.error || "Unknown error"));
        }
      })
      .catch(error => {
        setLoading(false);
        console.error('Error:', error);
        setResponse("Error: " + (error.message || "Unknown error"));
      });
  };

  const handleReset = () => {
    setUserInput('');
    setResponse('');
    setLoading(false);
  };

  function formatResponse(response) {
    return response.split('\n').map((line, index) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ));
  }

  return (
    <div className="Search">
      <h1>Search Page</h1>
      <form onSubmit={handleSubmit}>
        <textarea
          value={userInput}
          onChange={handleInputChange}
          placeholder="Search the NEC"
          rows="2"
        />
        <div className="buttons">
          <button type="button" onClick={handleReset}>Reset</button>
          <button type="submit">Search</button>
        </div>
      </form>
      <p>{loading ? `Loading${'.'.repeat(dots)}` : formatResponse(response)}</p>
    </div>
  );
}

export default Search;